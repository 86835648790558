import React, { Component} from 'react'
import {
	Card,
	Container,
	Header,
	Image,
	Pagination,
} from 'semantic-ui-react'
import {Link, navigate, graphql} from 'gatsby'
import Seo from '../components/SEO'
import Layout from '../components/Layout'
import * as styles from './styles/pages.module.css'
import {
	mobileMaxWidth,
	resizeTimer,
	tabletMaxWidth
} from '../globalVar'


export default class Blog extends Component {
	state = {
		currentPage:1,
		limit:6,
		itemsPerRow: 2,
		paginationRange:null,
		timeOut:null
	}

	componentDidMount() {
		this.handleParameters();
		this.handler();

		window.addEventListener('resize', this.resizeTimerFunc);
	}

	resizeTimerFunc = () => {
		clearTimeout(this.state.timeOut1);
		const temp = setTimeout(this.handler, resizeTimer);
		this.setState({
			timeOut1:temp
		})
	}

	componentWillUnmount() {
		clearTimeout(this.state.timeOut1);
		window.removeEventListener('resize', this.resizeTimerFunc);
	}

	handler = () => {
		//computer
		if (window.innerWidth > tabletMaxWidth ) {
			this.setState({
				paginationRange:4,
				itemsPerRow:3
			})
		} //tablet
		else if (window.innerWidth > mobileMaxWidth ) {
			this.setState({
				paginationRange:2,
				itemsPerRow:2
			})
		} else {  //mobile
			this.setState({
				paginationRange:1,
				itemsPerRow:1
			})
		}
	}

	handleParameters = () => {
		const {location} = this.props
		if(location.search) {
			const parameters = location.search.split("&")
			/*important checks if current result is already matched,
			 *without this if statement, there'll be an infinite loop
			 */
			for( let i =0; i < parameters.length; i++) {
				if(parameters[i].split("=")[0] === "page" || parameters[i].split("=")[0] === "?page") {
					this.setState({ currentPage:parameters[i].split("=")[1] })
				}else {
					//unplanned parameters
				}
			}

		}
	}

	blogPageCards = (blog) =>{
		return (
			<Card key={blog.node.frontmatter.slug} as={ Link } to={`/blog/${blog.node.frontmatter.slug}`} >
				<Image src={blog.node.frontmatter.thumbnail} wrapped ui={false} />
				<Card.Content className={styles.card}>
					<Card.Header>
						{blog.node.frontmatter.title}
					</Card.Header>
					<Card.Meta>
						{blog.node.frontmatter.date}
					</Card.Meta>
					<Card.Description>
						{blog.node.frontmatter.excerpt}<Link to={`/blog/${blog.node.frontmatter.slug}`}> Read More </Link>
					</Card.Description>
				</Card.Content>
			</Card>
		)
	}


	render() {
		const data = this.props.data.allMarkdownRemark.edges
		const {
			currentPage,
			limit,
			itemsPerRow,
			paginationRange
		} = this.state
		let slicedBlogPage
		let length = 0
		let totalPages;

		slicedBlogPage = data

		length = slicedBlogPage.length
		totalPages=Math.ceil(length/limit)
		slicedBlogPage = slicedBlogPage.slice( (currentPage-1)*limit, limit*currentPage)
		const {
			blogPageCards
		} = this
		const location = this.props.location
		return (
			<Layout location={location} backButton={true}>
				<Seo
						canonicalpath="/blog"
						title={"Stories and Tips"}
						description="Get inspired by stories of your fellow negosyantes. Jump start your business with these resources or add new products to your menu and keep your customers coming back for more."
					/>
				<Container className={styles.container}>
					<Header className={styles.superHeader} >
						<h1>Stories and Tips</h1>
					</Header>
					<Card.Group itemsPerRow={itemsPerRow} link>
						{slicedBlogPage.map(blogPageCards)}
					</Card.Group>
					{totalPages > 1 && (
						<React.Fragment>
							<br/>
							<Pagination
								activePage={currentPage}
								onPageChange={(e, {activePage})=>{
									this.setState({
										currentPage:activePage
									})
									navigate(`${location.pathname}?page=${activePage}`)
								}}
								ellipsisItem={null}
								boundaryRange={null}
								siblingRange={paginationRange}
								totalPages={totalPages}
								pageItem={{
									className:styles.paginationItem
								}}
								firstItem={{
									className:styles.paginationItem,
									content:"<<"
								}}
								lastItem={{
									className:styles.paginationItem,
									content:">>"
								}}
								nextItem={{
									className:styles.paginationItem,
									content:">"
								}}
								prevItem={{
									className:styles.paginationItem,
									content:"<"
								}}
							/>
						</React.Fragment>
					)}
				</Container>
			</Layout>
		)
	}
}

export const data = graphql`
	query BlogQuery {
		allMarkdownRemark(
				sort: {order:[ASC, DESC], fields:[frontmatter___order, frontmatter___date]}
			) {
			edges {
				node {
					frontmatter {
						slug
						title
						excerpt
						thumbnail
						date(formatString: "MMMM DD, YYYY")
					}
				}
			}
		}
	}
`